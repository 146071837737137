<template>
  <div class="shop">
    <!-- 标题 -->
    <!-- <div>
      <van-sticky>
        <van-nav-bar title="便民商店" />
      </van-sticky>
    </div> -->
    <!-- 搜索 -->
    <van-sticky :offset-top="0">
      <div>
        <van-search
          v-model="search"
          show-action
          label="搜索"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        >
          <template #action>
            <div @click="onSearch">搜索</div>
          </template>
        </van-search>
      </div>
    </van-sticky>
    <!-- tab栏 -->
    <van-sidebar
      v-model="activeKey"
      style="position: fixed; overflow-y: scroll; height: 100%; width: 95px"
    >
      <van-sidebar-item title="全部" @click="TypeKind(undefined)" />
      <van-sidebar-item
        :title="item.Name"
        v-for="item in hangye"
        :key="item.Coding"
        @click="TypeKind(item.Coding)"
      />
    </van-sidebar>
    <div
      class="information"
      style="
        position: relative;
        left: 25%;
        width: 75%;
        overflow-y: scroll;
        height: 100%;
      "
    >
      <!-- 全部 -->
      <van-list
        v-model="loading"
        :finished="finished"
        offset="50"
        @load="onLoad"
      >
        <div class="center" v-for="item in list" :key="item.index">
          <!-- <router-link :to="'/article/detail/' + item.RIId"> -->
          <div class="shopTitle van-ellipsis">{{ item.Title }}</div>
          <div class="Content" style="padding: 8px 10px">
            <div style="display: inline-block" class="ContentA">
              <van-image
                style="width: 100px; height: 85px"
                :src="item.ShopImgUrl"
              >
                <template style="width: 100px; height: 85px" v-slot:error>
                  <img
                    src="https://tkdlapi.zhsq.cloud//upload/images/2021/8/7d73f19d-f5c.jpg"
                    alt=""
                  />
                </template>
              </van-image>
            </div>
            <div
              style="
                display: inline-block;
                margin-left: 43px;
                vertical-align: top;
                width: 55%;
              "
              class=""
            >
              <span
                style="color: #333; font-size: 14px"
                class="van-multi-ellipsis--l3"
                ><van-icon name="location" />{{ item.Address }}</span
              >
              <br />
              <div style="font-size: 12px; color: #333"><van-icon name="manager" />{{ item.Charger }}</div>
              <br />
              <div class="centertime" style="color: #333">
                <a
                  :href="'tel:' + item.Mobile"
                  style="color: #000; text-decoration: underline;"
                  ><van-icon name="phone" />{{ item.Mobile }}</a
                >
              </div>
            </div>
          </div>
          <!-- </router-link> -->
        </div>
      </van-list>
      <van-pagination
        style="margin-bottom: 30px"
        v-model="listfrom.page"
        :total-items="listfrom.limit"
        :page-count="page"
        mode="simple"
        @change="pageclick()"
      />
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
  </div>
</template>
<script>
import { WeGetShopPage } from "@/api/RealInfo";
import { GetCodeEntrysList } from "@/api/ResumeJS";
export default {
  data() {
    return {
      activeKey:0,
      list: {}, //资讯列表
      hangye: [], //行业列表
      listfrom: {
        page: 0,
        limit: 10,
        shopTypeKind:undefined,
        title:undefined,
      }, //查询传参
      page: "0",
      search: "", //搜索内容
      hidden: false, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    };
  },
  methods: {
    // 点击分页
    pageclick(){
      console.log(this.listfrom.shopTypeKind);
      this.getList();
    },
    // 点击行业列表
    TypeKind(e){
      this.listfrom.shopTypeKind = e;
      this.getList();
    },
    // 获取行业
    EntrysList() {
      GetCodeEntrysList({ kind: 310 }).then((res) => {
        this.hangye = res.data.data;
      });
    },
    // 获取分页列表
    getList() {
      WeGetShopPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              this.page = Math.ceil(res.data.count / 10);
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
                if (this.list[i].ShopImgUrl != "rejected") {
                  this.list[i].ShopImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // 下拉刷新
    onLoad() {
      // this.listfrom.page = this.listfrom.page + 1;
      // this.getList();
    },
    // 搜索
    onSearch(row) {
      this.listfrom.page = 1;
      this.getList();
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
  mounted() {
    this.EntrysList();
    this.getList();
  },
};
</script>
<style>
.shopTitle {
  color:#188DFA;
  padding: 10px;
}
.shop .information .center {
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: rgb(246, 243, 243);
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>